import Glide, {Controls, Breakpoints, Swipe} from "@glidejs/glide/dist/glide.modular.esm";

// === sliders ===
// btw, glide is a great library name.
// not like some other library exists under the same name. it's not very popular, of course. 100%
new Glide(".section-1", {
    gap: 30,
    perView: 1,
    rewind: false,
    breakpoints: {
        1020: {
            gap: 14,
        },
    },
}).mount({Controls, Breakpoints, Swipe});
new Glide(".section-5", {
    gap: 40,
    perView: 2,
    rewind: false,
    breakpoints: {
        750: {
            perView: 1,
            gap: 12,
        },
    },
}).mount({Controls, Breakpoints, Swipe});
new Glide(".section-9", {
    gap: 40,
    perView: 1,
    rewind: false,
}).mount({Controls, Breakpoints, Swipe});

for (const img of document.getElementsByTagName("img")) {
    img.addEventListener("dragstart", (e) => e.preventDefault());
}

// === section 9 comparsions ===

function getBorderWidth() {
    return document.body.getBoundingClientRect().width > 870 ? 4 : 1;
}

function resetElements(el) {
    const img = el.querySelector(".s9__img_left");
    const slider = el.querySelector(".s9__img-slider");
    const border = el.querySelector(".s9__border-right");

    const bbox = el.getBoundingClientRect();

    const borderWidth = getBorderWidth();

    img.style.width = (bbox.width / 3 * 2) + "px";
    slider.style.left = (bbox.width / 3 * 2 - borderWidth / 2) + "px";
    border.style.width = (bbox.width / 3 + borderWidth) + "px";

    return {img, slider, border};
}

function initCompare(el) {
    const {img, slider, border} = resetElements(el);

    let isSliding = false, bbox;

    const onMove = e => {
        e.preventDefault();
        if (!isSliding) return;

        const w = bbox.width;
        const left = (e.changedTouches ? e.changedTouches[0] : e).pageX;
        const cursorX = Math.min(Math.max(left - bbox.left, 25), w - 25);
        const borderWidth = getBorderWidth();

        img.style.width = cursorX + "px";
        slider.style.left = (cursorX - borderWidth / 2) + "px";
        border.style.width = (w - cursorX + borderWidth) + "px";
    };
    const onStart = e => {
        e.preventDefault();
        e.stopPropagation();
        isSliding = true;
        bbox = el.getBoundingClientRect();
        window.addEventListener("mousemove", onMove);
        window.addEventListener("touchmove", onMove);
    };
    const onEnd = () => {
        isSliding = false;
        window.removeEventListener("mousemove", onMove);
        window.removeEventListener("touchend", onMove);
    }
    slider.addEventListener("mousedown", onStart);
    slider.addEventListener("touchstart", onStart);
    window.addEventListener("mouseup", onEnd);
    window.addEventListener("touchend", onEnd);
}

for (const el of document.getElementsByClassName("s9__slide-inner")) {
    initCompare(el);
}

window.addEventListener("resize", () => {
    for (const el of document.getElementsByClassName("s9__slide-inner")) {
        resetElements(el);
    }
});
